<template>
  <div class="footerConnect outer-wrap">
    <div class="footer-info inner-wrap">
      <p>
        By clicking the button above, you provide your written signature
        expressly consenting to receive communications via live telephone, an
        automatic dialing system, pre-recorded message, or text message from
        representatives or agents of SmartMatch Insurance Solutions, its
        subsidiaries, affiliates and their partner companies at the telephone
        number provided including your wireless number (if provided) as well as
        via email regarding Medicare insurance options. Your consent to receive
        communications in this way is not required as a condition of purchasing
        any goods or services. Your telephone company may impose additional
        charges for text messages, and you may revoke your consent at any time
        through any reasonable manner. You expressly consent to receive phone
        calls and email regardless of whether you are on any Federal or state
        DNC (Do Not Call) and/or DNE (Do Not Email) list or registry. Find their
        information online at
        <a
          href="https://www.donotcall.gov"
          target="_blank"
        >https://www.donotcall.gov</a>. You acknowledge that you have read and understand all of the
        <span
          class="link"
          @click="$refs.terms.activate()"
        >TERMS &amp; CONDITIONS</span>
        and
        <span class="link" @click="$refs.privacy.activate()">PRIVACY POLICY</span>
        of this site.
      </p>

      <p>
        Should you call the number listed above, you will speak with a licensed
        insurance agent who can provide information about Medicare insurance;
        this licensed insurance agent may also be able to provide plan and
        pricing options to you for the purposes of selling a Medicare insurance
        product. You are not required to purchase any goods or services. Neither
        SmartMatch Insurance Solutions nor its licensed insurance agents are
        connected with or endorsed by the U.S. government or the federal
        Medicare program.
      </p>

      <p>
        SmartMatch Insurance Solutions is licensed to sell insurance products in
        the following states: AL, AR, AZ, CA, CO, CT, DE, FL, GA, IA, ID, IL,
        IN, KS, KY, LA, MD, MI, MN, MO, MS, MT, NC, ND, NE, NH, NJ, NM, NV, OH,
        OK, OR, PA, RI, SC, SD, TN, TX, UT, VA, WI, WV, WY. SmartMatch Insurance
        Solutions is not associated with or endorsed by the U.S. Centers for
        Medicare & Medicaid Services, any government websites, or publications.
      </p>

      <p>
        The contents of this website are subject to change without notice. We
        believe the sources used to provide this information are reliable.
        However, we do not guarantee or warrant the accuracy of this
        information. SmartMatch Insurance Solutions provides the material on
        this website for informational purposes only. If you would like to find
        more information about the Government Medicare program, please visit the
        Official US Government Site for People with Medicare located at
        www.medicare.gov.
      </p>
      <p>Last modified March 25, 2020.</p>

      <Modal ref="privacy">
        <h2>Privacy Policy</h2>
        <div>
          We are committed to protecting your privacy. SmartMatch Insurance
          Solutions is an online source for people seeking to learn more about
          their insurance options. It is important for you to know and
          understand how our website(s) may collect and use your information and
          the steps we take to protect your privacy. Please read this carefully
          and note that this Privacy Policy only applies to this website.
        </div>
        <h3>Consent</h3>
        <div>
          By using or accessing this website, you are giving SmartMatch
          Insurance Solutions consent to access and use aggregate-level
          information about your website visit and agree to the terms of use
          herein.
        </div>
        <h3>Information Collection</h3>
        <div>
          This policy helps to explain how, why and when we collect information
          about your website experience on smartmatchinsurancesolutions.com.
          Cookies may be sent to visitors of this website. “Cookies” are
          text-only pieces of information that a website transfers to an
          individual’s hard drive or other web site-browsing equipment for
          record-keeping purposes. Cookies allow the website to remember
          important information that will make your use of the site more
          convenient. A cookie will typically contain the name of the domain
          from which the cookie has come, the “lifetime” of the cookie, and a
          randomly generated, unique number or other value. Like most websites,
          we use cookies to analyze user activity in order to improve the
          website and your overall experience. For example, using cookies, we
          can look at aggregate patterns, such as the average number of visits a
          page per day. We can use such analysis to gain insights about how to
          improve the functionality and user experience of the website.
        </div>
        <h3>Changes to This Policy</h3>
        <div>
          SmartMatch Insurance Solutions reserves the right to modify this
          privacy statement at any time, so please review it frequently. If we
          make material changes to this policy, we will notify you here. An
          updated copy of this Privacy Policy will be clearly posted and
          accessible on this website at all times.
        </div>
      </Modal>
      <Modal ref="terms">
        <h2>Terms &amp; Conditions</h2>
        <h3>Terms of Use Agreement</h3>
        <div>
          This Terms and Conditions of Use Agreement (“Terms of Use”)
          constitutes a binding agreement between you and SmartMatch Insurance
          Solutions. Please read carefully through all sections of these Terms
          of Use. By accessing this website you agree to be bound by these Terms
          of Use. Please be advised that any information you submit to
          SmartMatch Insurance Solutions, through a customer service
          representative, on our site, via emails, or otherwise, becomes the
          property of SmartMatch Insurance Solutions. If you do not acknowledge
          and agree to these Terms of Use, then do not provide your information.
          Your access to, and use of smartmatchinsurancesolutions.com is subject
          to these Terms of Use and all applicable laws. These Terms of Use may
          be changed by us from time to time without notice to you, and the
          latest version will be posted on this Site.
        </div>
        <h3>About This Site</h3>
        <div>
          Smartmatchinsurancesolutions.com (hereinafter “the Site” or “this
          Site”) is an online resource for people seeking to learn more about
          their insurance options. SmartMatch Insurance Solutions does not
          endorse any particular insurance plan, provider, or agent. Any
          information provided about any particular insurance plan, provider, or
          agent shall not be construed as an endorsement by SmartMatch Insurance
          Solutions.
        </div>
        <h3>Copyright and Proprietary Rights Information</h3>
        <div>
          This website is owned and maintained by SmartMatch Insurance
          Solutions. No content or images may be downloaded, or reproduced
          without our prior and explicit consent.
        </div>
        <div>
          While every effort is made to ensure the accuracy of the content of
          smartmatchinsurancesolutions.com, we cannot be held accountable for
          typographical errors, errors of fact, or any other error or
          inconsistency found on this website. We hereby disclaim all liability
          from any damages resulting from the use of this website, or any
          services provided on any SmartMatch Insurance Solutions website.
        </div>
        <h3>Links to Third Party Sites</h3>
        <div>
          This Site may contain links to sites that are controlled by third
          parties. These linked sites are not under the control of SmartMatch
          Insurance Solutions, and SmartMatch Insurance Solutions is not
          responsible for the contents of any linked site or any link contained
          in a linked site.
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "FooterConnect",
  components: {
    Modal
  }
};
</script>

<style lang="scss" scoped>
.footerConnect {
  background: #2b153d;
  margin: auto;
  padding: 40px 0;
  a {
    color: mediumpurple;
    &:hover {
      color: rebeccapurple;
      text-decoration: underline;
    }
  }

  @media (max-width: 991px) {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }

  p {
    margin-top: 0;
    font-size: 12px;
    color: #fff;
    line-height: 17.4px;

    span {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
