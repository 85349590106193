<template>
  <div class="connect">
    <div class="dots">
      <nav-bar-connect></nav-bar-connect>
      <div class="inner-wrap mm-easy">
        <div class="mm-easy-inner">
          <h1>
            Medicare fundamentals presented by
            <strong>SmartConnect</strong>
          </h1>
          <p>
            You have options - learn how Medicare can be more affordable than
            employer plans
          </p>
        </div>
      </div>
      <div class="content outer-wrap">
        <div class="row">
          <div class="col-8">
            <div class="wistia_responsive_padding" style="height: 100%; width: 90%;">
              <div class="wistia_responsive_wrapper" style="height: 100%; width: 90%;">
                <div
                  class="wistia_video_foam_dummy"
                  data-source-container-id
                  style="border: 0px; display: block; height: 0px; margin: 0px; padding: 0; position: static; visibility: hidden; width: auto;"
                ></div>
                <iframe
                  src="https://fast.wistia.net/embed/iframe/1jx49ernhf?videoFoam=true"
                  title="United Medicare Advisors Video"
                  allowtransparency="true"
                  frameborder="0"
                  scrolling="no"
                  class="wistia_embed"
                  name="wistia_embed"
                  width="585px"
                  height="329px"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                ></iframe>
              </div>
            </div>
          </div>
          <div class="col-4">
            <h2>Meet the presenters</h2>
            <div class="presenterCard">
              <div class="photoDiv">
                <img
                  src="/Vanessa.png"
                  class="img-fluid navConnectImg"
                  alt="Photo of Vanessa Henry"
                />
              </div>
              <div class="infoDiv">
                <h3>Vanessa Henry</h3>
                <p>Director of Partner Relations</p>
                <p>
                  <i>SmartMatch Insurance Solutions</i>
                </p>
              </div>
            </div>

            <div class="presenterCard bottomCard">
              <div class="photoDiv">
                <img
                  src="/Matthew.png"
                  class="img-fluid navConnectImg"
                  alt="Photo of Vanessa Henry"
                />
              </div>
              <div class="infoDiv">
                <h3>Matthew Applequist</h3>
                <p>Director of Sales Training</p>
                <p>
                  <i>SmartMatch Insurance Solutions</i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="whatWeDoText">
          <h2>Medicare is what we do</h2>
          <p>
            SmartConnect is an exclusive program from SmartMatch, a leading
            independent Medicare agency. It was created specifically for working
            adults like you, who are Medicare eligible, but may not have fully
            explored the benefits of Medicare coverage.
          </p>
          <br />
          <p>
            In this quick video, we highlight important Medicare topics such as
            enrollment timelines, insurance types, and highlight key strategies.
            SmartConnect is your personal advisor, helping you through the
            Medicare process with confidence.
          </p>
          <br />
          <p>
            Call
            <a href="tel: 8559005482">1 (855) 900-5482</a> to connect with one
            of our licensed insurance agents today.
          </p>
        </div>
      </div>
      <footer-connect></footer-connect>
    </div>
  </div>
</template>

<script>
import NavBarConnect from "@/components/NavBarConnect.vue";
import FooterConnect from "@/components/FooterConnect.vue";

export default {
  name: "connect",
  data() {
    return {};
  },
  components: {
    NavBarConnect,
    FooterConnect
  },
  metaInfo: {
    title: "Medicare fundamentals presented by SmartConnect",
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Learn about important Medicare topics such as enrollment timelines and insurance types. SmartConnect helps you through the Medicare process with confidence."
      },
      {
        vmid: "og:title",
        property: "og:title",
        content: "Medicare fundamentals presented by SmartConnect"
      },
      {
        vmid: "twitter:title",
        property: "twitter:title",
        content: "Medicare fundamentals presented by SmartConnect"
      },
      {
        vmid: "og:description",
        property: "og:description",
        content:
          "Learn about important Medicare topics such as enrollment timelines and insurance types. SmartConnect helps you through the Medicare process with confidence."
      },
      {
        vmid: "twitter:description",
        property: "twitter:description",
        content:
          "Learn about important Medicare topics such as enrollment timelines and insurance types. SmartConnect helps you through the Medicare process with confidence."
      }
    ]
  }
};
</script>

<style lang="scss" scoped>
h2 {
  text-align: left;
  margin: 0 0 1em;
  line-height: 23.2px;
}
p {
  margin: 0;
}
.bottomCard {
  margin-top: 2em;
}
.wistia_responsive_wrapper {
  width: 90%;
  @media (max-width: 991px) {
    width: 100% !important;
    margin-bottom: 2rem;
  }
}
.wistia_responsive_padding {
  @media (max-width: 991px) {
    width: 100% !important;
  }
}
.whatWeDoText {
  padding: 0 5em 5em;
  @media (max-width: 1200px) {
    padding: 2em 5%;
  }
  h2 {
    font-size: 36px;
    line-height: 42px;
    @media (max-width: 500px) {
      font-size: 30px;
    }
  }
  a {
    color: #62626c;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.presenterCard {
  display: flex;
  align-items: center;
  .photoDiv {
    width: 82px;
    height: 82px;
  }
  h3 {
    margin: 0;
    font-size: 20px;
    text-align: left;
  }

  .infoDiv {
    padding-left: 10px;
  }
}
.content {
  background: #fff;
  margin: auto;
  .row {
    padding: 5em 5em;
    @media (max-width: 1200px) {
      padding: 2em 5%;
    }
  }
  .col-8 {
    flex: 0 0 63%;
    max-width: 63%;
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .col-4 {
    flex: 0 0 33%;
    max-width: 33%;
    @media (max-width: 991px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
.connect {
  background-image: url("/purple-background2.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
}

.dots {
  background-image: url("/dots.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 70%;
}

.mm-easy {
  padding: 45px 0;

  h1 {
    text-align: left;
    margin: 0 0 0.8em 0;
    font-size: 46px;
    font-weight: 400;
    color: #fff;
    @media (max-width: 768px) {
      font-size: 28px;
    }
  }

  p {
    // max-width: 680px;
    font-size: 22px;
    color: #fff;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .mm-easy-inner {
    width: 100%;
    @media (max-width: 1200px) {
      width: 90%;
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}
</style>
