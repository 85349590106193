<template>
  <div class="background" v-if="open" @click="open = false">
    <div class="modal" @click.stop>
      <div class="bar-wrap" @click="open = false">
        <span class="bar-1"></span>
        <span class="bar-2"></span>
      </div>
      <div class="innerModal">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      open: false
    };
  },
  methods: {
    activate() {
      this.open = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.background {
  z-index: 2;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal {
  position: fixed;
  width: 90%;
  max-width: 700px;
  height: 90%;
  max-height: 800px;
  background-color: white;
  border-radius: 5px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  * {
    text-align: left;
    font-weight: 400;
    margin: 25px;
  }
}

.innerModal {
  overflow-y: auto;
  color: #727272;
  position: absolute;
  text-align: left;
  background-color: white;
  border-radius: 5px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bar-wrap {
  width: 25px;
  height: 25px;
  background: #3a3a3a;
  border: 1px solid #fff;
  border-radius: 25px;
  position: absolute;
  top: -38px;
  right: -34px;
  z-index: 10;

  &:hover {
    background: #b53030;
    cursor: pointer;
  }

  .bar-1 {
    position: absolute;
    border-radius: 0;
    width: 15px;
    top: 13px;
    left: -20px;
    transform: rotate(225deg);
    background: #fff;
    height: 2px;
    margin-top: -1px;
  }

  .bar-2 {
    position: absolute;
    border-radius: 0;
    width: 15px;
    top: 13px;
    left: -20px;
    transform: rotate(-225deg);
    background: #fff;
    height: 2px;
    margin-top: -1px;
  }
}
</style>
