<template>
  <div class="navbar-connect outer-wrap">
    <div class="inner-wrap row">
      <div class="col-1">
        <img
          src="@/assets/smis-logo.png"
          class="img-fluid nav-connect-img"
          alt="Smart Match Insurance Solutions logo"
        />
      </div>
      <div class="col-2">
        <div class="navText floatRight text-right align-self-center">
      <h1>Call a licensed insurance agent | M - F, 8am - 5pm CST</h1>
      <div class="flex">
        <img
          src="@/assets/phone-orange.png"
          class="img-responsive ml-auto navImg pr-2"
          alt="phone icon"
        />
        <a href="tel: 8559005482"
          ><h3 class="mb-0"><strong>1-855-900-5482</strong></h3></a
        >
        <h3 class="mb-0 horzLine">
          | <span><strong>TTY: 711</strong></span>
        </h3>
      </div>
    </div>
        </div>
        <div
          class="navText floatRight text-right align-self-center"
          v-show="!connect"
        >
          <h1>Call a licensed insurance agent | M - F, 8am - 5pm CST</h1>
          <div class="flex">
            <img
              src="@/assets/phone-orange.png"
              class="img-responsive ml-auto navImg pr-2"
              alt="phone icon"
            />
            <a href="tel: 8559005482"
              ><h3 class="mb-0 "><strong>(855) 900-5482</strong></h3></a
            >
            <h3 class="mb-0 horzLine">
              | <span><strong>TTY: 711</strong></span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBarConnect",
  data() {
    return {
      connect: true
    };
  },
  created() {
    if (this.$route.name == "medicare101") {
      this.connect = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.navText {
  h1 {
    text-align: right;
    font-family: Arial;
    font-size: 14px !important;
    color: #6e6d6f;
    margin: 0;
    line-height: 2;
    @media (max-width: 991px) {
      text-align: center;
    }
    @media (max-width: 360px) {
      font-size: 12px !important;
    }
  }
  h3 {
    color: #ed5c2e;
    margin: 0;
    @media (max-width: 991px) {
      font-size: 20px;
    }
  }
  .horzLine {
    color: #6e6d6f !important;
    padding-left: 10px;
    span {
      color: #45295c;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 991px) {
      justify-content: center;
    }
  }
  a {
    padding: 0;
    text-decoration: none;
  }
  img {
    padding-right: 10px;
    margin-left: 0;
    height: auto;
  }
}
.navbar-connect {
  background: #fff;
  margin: auto;
  padding: 8px 0px;
}

.col-1 {
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 1200px) {
    flex: 0 0 45%;
    max-width: 45%;
    padding-left: 5%;
  }

  @media (max-width: 991px) {
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
  }
  img {
    @media (max-width: 768px) {
      max-width: 150px;
    }
  }
}

.col-2 {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 1200px) {
    flex: 0 0 45%;
    max-width: 45%;
    padding-right: 5%;
  }
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
    padding-right: 0;
    text-align: center;
  }

  p {
    margin: 0;
    text-align: right;
    font-size: 18px;
    @media (max-width: 991px) {
      text-align: center;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
}

.nav-connect-img {
  max-width: 180px;
}

.vanessa-phone {
  img {
    max-width: 27px;
    margin-right: 0.4em;
    vertical-align: middle;
    @media (max-width: 576px) {
      display: none;
    }
  }

  h2 {
    font-size: 24px;
    color: #ed5c2e;
    text-decoration: none;
    text-align: right;
    font-weight: 500;
    margin: 0;
    @media (max-width: 991px) {
      text-align: center;
    }
    @media (max-width: 576px) {
      font-size: 18px;
    }
  }

  a {
    text-decoration: none;
    vertical-align: middle;
  }
}
</style>
